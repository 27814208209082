/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import './UserDetailsComponent.css';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { api } from '../../../APIs/api';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';

const dropdownStyles = {
  border: '1px solid #ccc',
  maxHeight: '150px',
  overflowY: 'auto',
  position: 'absolute',
  background: 'white',
  width: '100%',
  zIndex: 1000,
  marginTop: '5px',
  padding: 0
};

const listItemStyles = {
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '1px solid #ddd',
  listStyleType: 'none'
};

const UserDetailsModal = ({ handleOtpModal }) => {
  const navigate = useNavigate();
  const [citiesData, setCitiesData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    city: '',
    email: '',
    phoneNumber: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    city: '',
    email: '',
    phoneNumber: ''
  });
  const [isLoader, setIsLoader] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const nameInputRef = useRef(null);
  const cityInputRef = useRef();

  const getCityListApi = async () => {
    try {
      const searchCityRes = await api.get(
        `${BASE_URL}${API_URL.SEARCH_CITIES}?city=${formValues.city}`
      );
      if (searchCityRes && searchCityRes.status) {
        const convertedCities = searchCityRes.data.map((item) => ({
          label: item.name,
          id: item._id.$oid
        }));
        setCitiesData(convertedCities);
      } else {
        toast.error(searchCityRes.message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      cityInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (formValues.city.length > 1) {
      getCityListApi();
    } else {
      setFilteredCities([]);
      setIsDropdownOpen(false);
    }
  }, [formValues.city]);

  useEffect(() => {
    if (open && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));

    if (id === 'city' && value.length > 1) {
      const filtered = citiesData.filter((city) =>
        city.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCities(filtered);
      setIsDropdownOpen(filtered.length > 0);
    } else {
      setFilteredCities([]);
      setIsDropdownOpen(false);
    }
    validateInput(id, value);
  };

  const handleCitySelect = (city) => {
    setFormValues((prevValues) => ({ ...prevValues, city: city.label }));
    setIsDropdownOpen(false);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 100);
  };

  const validateInput = (field, value) => {
    let error = '';
    switch (field) {
      case 'name':
        if (value.length > 50) {
          error = 'Name cannot exceed 50 characters';
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          error = 'Name only accepts letters and spaces';
        }
        break;
      case 'city':
        if (value.length > 50) {
          error = 'City cannot exceed 50 characters';
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          error = 'City only accepts letters and spaces';
        }
        break;
      case 'email': {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value.length > 50) {
          error = 'Email cannot exceed 50 characters';
        } else if (!emailRegex.test(value)) {
          error = 'Please enter a valid email address';
        }
        break;
      }
      case 'phoneNumber':
        if (!/^(\+?\d{1,15})$/.test(value)) {
          error =
            'Phone number must be between 10 and 15 characters and only contain numbers and +';
        } else if (value.length < 10 || value.length > 15) {
          error = 'Phone number must be between 10 and 15 characters';
        }
        break;

      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error
    }));
  };

  const isFormValid = () =>
    Object.values(formValues).every((field) => field) &&
    !Object.values(errors).some((error) => error);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsButtonEnabled(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error('Please verify that you are not a robot!', { autoClose: 3000 });
      return;
    }
    if (!isFormValid()) return;
    setIsLoader(true);
    try {
      const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
      const campaignDetailsRaw = sessionStorage.getItem('campaign_details');
      let campaignDetails = {};

      if (campaignDetailsRaw) {
        try {
          campaignDetails = JSON.parse(campaignDetailsRaw);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error parsing campaign_details:', error);
          campaignDetails = {};
        }
      }

      const updatedTripDetails = {
        ...existingTripDetails,
        userDetails: formValues
      };
      sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));

      // Create an Enquiry payload
      const enquiryData = {
        name: formValues.name,
        mobile: formValues.phoneNumber,
        email: formValues.email,
        city: formValues.city,
        destination:
          existingTripDetails?.packageId === 'N/A' ? 'N/A' : existingTripDetails?.countryName,
        package_id:
          existingTripDetails?.packageId === 'N/A' ? 'N/A' : existingTripDetails?.packageId,
        month: existingTripDetails?.tripMonth,
        date_of_travel:
          existingTripDetails?.tripDate === 'N/A'
            ? 'N/A'
            : moment(existingTripDetails.tripDate).format('YYYY-MM-DD'),
        travelling_with: existingTripDetails?.vacationGroup,
        duration: existingTripDetails?.vacationDays,
        adults: Number(existingTripDetails?.tripMembers?.adults),
        children: Number(existingTripDetails?.tripMembers?.children),
        infants: Number(existingTripDetails?.tripMembers?.infants),
        hotel_rating: Number(existingTripDetails?.tripMembers?.hotelRating),
        include_flights: existingTripDetails?.tripMembers?.includeFlights,
        campaign_details: campaignDetails
      };

      // API integration
      const enquiryRes = await api.post(`${BASE_URL}${API_URL.CREATE_ENQUIRY}`, enquiryData);
      const { status, data } = enquiryRes;
      if (status) {
        toast.success(data, { autoClose: 3000 });
        handleCloseWithReset();
        navigate('/trip-confirmation');
        // handleOtpModal();
      } else {
        toast.error(data, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const handleCloseWithReset = () => {
    setFormValues({
      name: '',
      email: '',
      phoneNumber: '',
      city: ''
    });
    setErrors({
      name: '',
      email: '',
      phoneNumber: '',
      city: ''
    });
    setCitiesData([]);
    setFilteredCities([]);
    setIsDropdownOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          autoComplete="off"
          autoFocus
          inputRef={nameInputRef}
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={formValues.name}
          onChange={handleInputChange}
          required
          inputProps={{ maxLength: 50 }}
          helperText={errors.name}
          error={!!errors.name}
          disabled={isLoader}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          autoComplete="off"
          margin="dense"
          id="email"
          label="Email Id"
          type="email"
          fullWidth
          variant="outlined"
          value={formValues.email}
          onChange={handleInputChange}
          required
          inputProps={{ maxLength: 50 }}
          helperText={errors.email}
          error={!!errors.email}
          disabled={isLoader}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          autoComplete="off"
          margin="dense"
          id="phoneNumber"
          label="Phone Number"
          type="tel"
          fullWidth
          variant="outlined"
          value={formValues.phoneNumber}
          onChange={handleInputChange}
          required
          inputProps={{ minLength: 6, maxLength: 15 }}
          helperText={errors.phoneNumber}
          error={!!errors.phoneNumber}
          disabled={isLoader}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Box style={{ position: 'relative' }}>
          <TextField
            autoComplete="off"
            margin="dense"
            id="city"
            label="City"
            type="text"
            fullWidth
            variant="outlined"
            name="city"
            value={formValues.city}
            onChange={handleInputChange}
            required
            inputRef={cityInputRef}
            helperText={errors.city}
            error={!!errors.city}
            disabled={isLoader}
            onBlur={handleBlur}
          />
          {isDropdownOpen && (
            <ul style={dropdownStyles}>
              {filteredCities.map((city) => (
                <li key={city.id} onMouseDown={() => handleCitySelect(city)} style={listItemStyles}>
                  {city.label}
                </li>
              ))}
            </ul>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        container
        justifyContent={{ xs: 'center', md: 'center' }}
        alignItems="center"
        sx={{
          display: 'flex',
          justifyContent: { md: 'center', lg: 'center', xl: 'center' },
          marginTop: 2
        }}>
        <Box
          sx={{
            width: { xs: '100%', md: 'auto' },
            display: 'flex',
            justifyContent: 'center'
          }}>
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={onCaptchaChange} />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={3}
        xl={3}
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      />
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid container justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="error"
            fullWidth
            disabled={isLoader || !isButtonEnabled}
            onClick={(e) => {
              if (!isLoader) {
                handleSubmit(e);
              }
            }}
            sx={{
              fontFamily: 'gothamBold',
              backgroundColor: isFormValid() ? '#d32f2f' : '#d32f2f',
              color: isFormValid() ? '#fff' : '#fff',
              '&:disabled': {
                backgroundColor: '#d32f2f',
                color: '#fff',
                opacity: 0.5
              }
            }}>
            {isLoader ? (
              <CircularProgress size={24} style={{ color: '#1a90ff' }} />
            ) : (
              'Submit Details'
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={3}
        xl={3}
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      />
    </Grid>
  );
};

export default UserDetailsModal;
