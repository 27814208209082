export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PUBLIC_CMS_URL = process.env.REACT_APP_PUBLIC_CMS_URL;

export const API_URL = {
  HEADER_MENU: '/header-menu',
  FOOTER_MENU: '/footer-menu',
  RATING_COUNT: '/ratings',
  BUSINESS_COUNT: '/business-count',
  TIMELINE_SLIDER: '/timeline-slider',
  HIDDEN_GEMS: '/hidden-gems',
  REVIEW_LIST: '/review',
  WORLD_WISE_PLACES: '/world-wise-places',
  THEME_LIST: '/theme',
  GET_CONTINENT_LIST: '/get-continent',
  READY_TO_BOOK_TOUR_LIST: '/ready-to-book-tour',
  SAVE_SUBSCRIBE: '/save-subscribe',
  GET_ZONE_WISE_LIST: '/get-zone-wise-list',
  GET_ADVENTURES_LIST: '/get-adventures',
  QUICK_ENQUIRY: '/quick-enquiry',
  SEARCH_DESTINATION: '/search-destination',
  GET_PACKAGE_LIST: '/get-package-list',
  GET_PACKAGE_DETAILS: '/get-package-details',
  GET_TOP_DESTINATION: '/get-top-destinations',
  GET_LUXURY_TOURS: '/get-luxury-tours',
  GET_REVIEW_GALLERY: '/review-gallery',
  GET_META_DATA_LIST: '/meta-data',
  CREATE_ENQUIRY: '/create-enquiry',
  ENQUIRY_OTP_VERIFY: '/enquiry-otp-verify',
  WHY_KESARI_SELECT: '/why-kesari-select',
  SEARCH_CITIES: '/search-cities',
  GET_FAQ_LIST: '/faq',
  TIMELINE_SLIDER_TRACK: '/timeline-slider-track'
};

export const IMAGE_DIR_PATH = {
  TIME_LINE_SLIDER_PATH: '/timelineslider/',
  HIDDEN_GEMS_PATH: '/hiddengems/',
  REVIEW_LIST_PATH: '/review/',
  WORLD_WISE_PLACES_PATH: '/worldwise/',
  THEME_PATH: '/theme/',
  READY_BOOK_TOUR_PATH: '/readybooktour/',
  USER_PROFILE_PIC_PATH: '/user/'
};
