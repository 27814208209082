import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardMedia, Skeleton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight, StarRateRounded } from '@mui/icons-material';
import { PUBLIC_CMS_URL, IMAGE_DIR_PATH } from '../../../APIs/endpoint';
import moment from 'moment';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import CustomButton from '../../common/Button';

const ReviewCardCommon = ({
  title,
  description,
  rating,
  category,
  user,
  date,
  imageUrls,
  isLoader
}) => {
  // const DefaultReviewImg = process.env.PUBLIC_URL + '/images/KesariLogo.svg';
  const settings = {
    dots: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: imageUrls.length > 1,
    autoplay: imageUrls.length > 1,
    autoplaySpeed: 3000
  };

  return (
    <Card
      sx={{
        maxWidth: '100%',
        minHeight: 320,
        maxHeight: 372.75,
        borderRadius: 2,
        m: 2,
        boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.16) !important',
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '15px 15px 40px 0px rgba(0, 0, 0, 0.2) !important',
          '& img': {
            transform: 'scale(1.1)'
          }
        }
      }}>
      <Slider {...settings}>
        {isLoader ? (
          <Skeleton variant="rectangular" width="100%" height={112} />
        ) : imageUrls.length === 0 ? (
          <CardMedia
            component="img"
            image={''}
            alt="default image"
            sx={{
              maxHeight: 120,
              width: 280,
              objectFit: 'cover',
              transition: 'transform 0.3s ease-in-out'
            }}
          />
        ) : (
          imageUrls.map((imageUrl, index) => (
            <CardMedia
              key={index}
              component="img"
              image={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.REVIEW_LIST_PATH}${imageUrl}`}
              alt={`Image ${index}`}
              sx={{
                maxHeight: 178,
                width: 280,
                objectFit: 'cover',
                transition: 'transform 0.3s ease-in-out'
              }}
            />
          ))
        )}
      </Slider>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <StarRateRounded sx={{ color: 'gold', padding: '0', margin: '0' }} />
          <Typography
            sx={{
              ml: 0.5,
              fontFamily: 'gothamBold',
              fontSize: '12px',
              color: '#000000CC'
            }}>
            {isLoader ? <Skeleton variant="text" width={20} height={28} /> : <>{rating}</>}
          </Typography>
          {category &&
            category.length > 0 &&
            category.map((cat, index) => (
              <Button
                key={index}
                variant="outlined"
                color="error"
                size="small"
                sx={{
                  ml: 1.5,
                  fontFamily: 'gothamBook',
                  fontSize: '10px'
                }}>
                {isLoader ? <Skeleton variant="text" width={60} height={25} /> : <>{cat}</>}
              </Button>
            ))}
        </Box>
        <Typography
          gutterBottom
          component="div"
          sx={{
            fontFamily: 'gothamBold',
            fontSize: '15px',
            lineHeight: '18px',
            color: '#000000CC',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}>
          {isLoader ? <Skeleton variant="text" width="100%" height={18} /> : <>{title}</>}
        </Typography>
        <Typography
          maxHeight="60px"
          sx={{
            fontFamily: 'gothamBook',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#00000080'
          }}>
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={60} />
          ) : (
            <> {description.length > 100 ? description.slice(0, 100) + '...' : description}</>
          )}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            mt: 2,
            alignItems: 'flex-end'
          }}>
          <Typography
            sx={{
              fontFamily: 'gothamBold',
              fontSize: '14px',
              lineHeight: '20px',
              color: '#000000CC'
            }}>
            {isLoader ? <Skeleton variant="text" width={80} height={30} /> : <>- {user}</>}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'gothamBook',
              fontSize: '12px',
              lineHeight: '20px',
              color: '#00000099'
            }}>
            {isLoader ? (
              <Skeleton variant="text" width={60} height={30} />
            ) : (
              <>{moment(date).format('MMM, YYYY')}</>
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const ReviewCard = ({ reviewData, isLoader, isListing, isDetails }) => {
  // const navigate = useNavigate();
  const [slider, setSlider] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const reviewDataLength = reviewData && reviewData.length;

  const getSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 600) return 1.2;
    if (width < 960) return 2.3;
    return 3.6;
  };

  const slidesToShow = getSlidesToShow();

  const goToPrev = () => {
    if (slider && currentIndex > 0) {
      slider.slickPrev();
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToNext = () => {
    if (slider && currentIndex < reviewData.length - 1) {
      slider.slickNext();
      setCurrentIndex(currentIndex + 1);
    }
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.6,
          centerMode: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2
        }
      }
    ]
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '1900px',
          margin: '0 auto',
          overflow: isListing ? '' : 'hidden',
          marginTop: '0px',
          backgroundColor: isListing ? 'inherit' : '#ffffff',
          borderRadius: isListing ? '0px' : '10px',
          width: '100%'
        }}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={2}
          sx={{
            pl: {
              xs: '0px',
              sm: '10px',
              md: 'calc(10% - 20px)',
              lg: isListing || isDetails ? 'calc(10% - 50px)' : 'calc(10% - 20px)'
            }
          }}>
          <Typography
            sx={{
              flex: 1,
              fontSize: { xs: '20px', sm: '30px', md: '32px', lg: '32px' },
              fontFamily: 'gothamBold',
              paddingLeft: '15px !important'
            }}>
            Kesari Select Reviews
          </Typography>

          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex' },
              paddingRight: {
                xs: '18px',
                sm: '20px',
                md: '150px',
                lg: isListing ? '0px' : '180px'
              }
            }}>
            <ChevronLeft
              onClick={goToPrev}
              sx={{
                padding: '7px',
                boxSizing: 'content-box',
                width: '18px',
                height: '18px',
                border: '1px solid',
                borderColor: currentIndex === 0 ? '#0000004D' : '#000',
                borderRadius: '100%',
                color: currentIndex === 0 ? '#ccc' : '#000',
                mr: '14px',
                cursor: currentIndex === 0 ? 'default' : 'pointer',
                pointerEvents: currentIndex === 0 ? 'none' : 'auto'
              }}
            />
            <ChevronRight
              onClick={goToNext}
              sx={{
                padding: '7px',
                boxSizing: 'content-box',
                width: '18px',
                height: '18px',
                border: '1px solid',
                borderColor: currentIndex >= reviewDataLength - slidesToShow ? '#0000004D' : '#000',
                borderRadius: '100%',
                color: currentIndex >= reviewDataLength - slidesToShow ? '#ccc' : '#000',
                cursor: currentIndex >= reviewDataLength - slidesToShow ? 'default' : 'pointer',
                pointerEvents: currentIndex >= reviewDataLength - slidesToShow ? 'none' : 'auto'
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            pl: {
              xs: '0px',
              sm: '10px',
              md: 'calc(10% - 20px)',
              lg: isListing || isDetails ? 'calc(10% - 50px)' : 'calc(10% - 20px)'
            }
          }}>
          <Typography
            sx={{
              flex: 1,
              fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px' },
              fontFamily: 'gothamBook',
              lineHeight: { xs: '22px', sm: '22px', md: '32px', lg: '32px' },
              mb: 2,
              pl: '15px !important',
              mt: 1,
              color: '#000000B2'
            }}>
            Discover what our customers love about Kesari Select in their own words.
          </Typography>
        </Box>

        {reviewDataLength > 0 ? (
          <Box
            sx={{
              pl: {
                xs: '0px',
                sm: '10px',
                md: 'calc(10% - 30px)',
                lg: isListing || isDetails ? 'calc(10% - 50px)' : 'calc(10% - 20px)'
              }
            }}>
            <Slider {...settings} ref={(slider) => setSlider(slider)} className="card-list">
              {reviewData.map((review, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: 1,
                    width: '350px'
                  }}>
                  <ReviewCardCommon
                    title={review.review_package_title}
                    description={review.review_description}
                    rating={review.review_rating}
                    category={review.review_tour_theme}
                    user={review.review_username}
                    date={review.review_date}
                    imageUrls={review.review_image}
                    isLoader={isLoader}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        ) : (
          <Box className="travellife_title">
            <Typography
              sx={{
                fontSize: { xs: '22px', md: '44px' },
                fontFamily: 'gothamBold',
                textAlign: 'center',
                height: reviewDataLength === 0 ? '205px' : 'auto',
                alignContent: 'center'
              }}
              className="travellife_title_head">
              Coming Soon...!
            </Typography>
          </Box>
        )}
      </Box>
      {/* <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box className="DiscoverGems_btn_container" mt={1} mb={5}>
          <CustomButton
            className="DiscoverGems_typo_btn"
            onClick={() => navigate('/product-review')}
            showArrow={true}>
            <Typography fontFamily="gothamBold">More Reviews</Typography>
          </CustomButton>
        </Box>
      </Box> */}
    </>
  );
};

export default ReviewCard;
