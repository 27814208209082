export const storeCampaignDetails = () => {
  const queryString = window.location.search;
  const campaignDetails = {};
  if (queryString) {
    const params = new URLSearchParams(queryString);
    params.forEach((value, key) => {
      campaignDetails[key] = value;
    });
  }
  sessionStorage.setItem('campaign_details', JSON.stringify(campaignDetails));
};

export const getCampaignDetails = () =>
  JSON.parse(sessionStorage.getItem('campaign_details') || '{}');

export const initializeCampaignCleanup = () => {
  const handleBeforeUnload = (event) => {
    const nextUrl = event.target.activeElement?.href;
    if (nextUrl && !nextUrl.startsWith(window.location.origin)) {
      sessionStorage.removeItem('campaign_details');
    }
  };
  window.addEventListener('beforeunload', handleBeforeUnload);
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
};
