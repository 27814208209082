/* eslint-disable no-console */
import { useEffect } from 'react';

const Chatbot = () => {
  const chatbotKey = process.env.REACT_APP_CHATBOT_KEY;
  const chatbotServerUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      console.error('Error details:', { message, source, lineno, colno, error });
    };
    return () => {
      window.onerror = null;
    };
  }, []);

  useEffect(() => {
    if (!chatbotKey || !chatbotServerUrl) {
      console.error(
        'Error: chatbotKey or chatbotServerUrl is undefined. Please check your .env file and ensure these variables are defined.'
      );
      return;
    }

    // Ensure ENGT_CHAT.onScrollToBottom is defined
    window.ENGT_CHAT = window.ENGT_CHAT || {};
    if (typeof window.ENGT_CHAT.onScrollToBottom !== 'function') {
      window.ENGT_CHAT.onScrollToBottom = () => {};
    }

    const scriptId = 'engati-chat-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.src =
        `${chatbotServerUrl}/static/js/widget.js?config=` +
        encodeURIComponent(
          JSON.stringify({
            bot_key: chatbotKey,
            welcome_msg: true,
            branding_key: 'default',
            server: chatbotServerUrl,
            e: 'p'
          })
        );
      document.head.appendChild(script);
    }

    // Add custom styles to change right to left
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
        .engt .engt-right-theme {
          left: 24px !important;
          right: unset !important;
        }
        .engt.engt-mobile-tab .engt-right-theme .engt-launch-icon-box {
          left: 32px !important;
        }
        .engt .engt-right-theme .engt-closed-callout .engt-closed-callout-text {
          left: 28px !important;
          position: fixed !important;
          bottom: 82px !important;
          width: 17% !important;
          border-radius: 8px 8px 8px 0 !important;
        }
        .engt.engt-mobile-tab .engt-right-theme .engt-launch-icon-box .engt-closed-callout-text {
          left: 18px !important;
          width: 47% !important;
        }
        .engt .engt-launch-icon-box .engt-launcher-icon {
          height: 44px !important;
          max-height: 44px !important;
          width: 44px !important;
          max-width: 44px !important; 
        }
        .engt.engt-mobile-tab .engt-wrapper-open .engt-popup {
          width: 94vw !important;
        }
        .engt .engt-wrapper {
          z-index: 1000 !important;
        }
      `;
    document.head.appendChild(styleTag);

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [chatbotKey, chatbotServerUrl]);

  return null;
};

export default Chatbot;
