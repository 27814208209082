import { Select, MenuItem, FormControl, Typography, Box } from '@mui/material';
import React from 'react';

const CustomSelect = ({ onChange, isFullWidth, options, title, selectedOption, sx }) => {
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };

  return (
    <Box className="custom-filter-content-box">
      <FormControl
        sx={{
          minWidth: 120,
          bgcolor: '#fff',
          ...sx
        }}>
        <Select
          fullWidth={isFullWidth}
          value={selectedOption}
          onChange={handleChange}
          displayEmpty
          sx={{
            width: { md: '280px' },
            '& .MuiSelect-select': { py: '9px' }
          }}>
          <MenuItem value="" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box component="img" src="./icons/filter-icon.svg" width={16} height={10} />
            <Typography
              component="span"
              fontFamily="inherit"
              fontWeight={400}
              fontSize={12}
              lineHeight="20px"
              mx="14px"
              color="#000000CC">
              {title}
            </Typography>
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option[0].toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
