import React from 'react';
import './VideoModal.css';
import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { CloseRounded, PlayArrow } from '@mui/icons-material';

const VideoModal = ({ open, handleClose }) => (
  <Box>
    <Button variant="contained" color="primary" onClick={() => open(true)}>
      <PlayArrow />
    </Button>
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          event.stopPropagation();
        } else {
          handleClose();
        }
      }}
      maxWidth="md"
      aria-labelledby="video-dialog-title"
      fullWidth
      PaperProps={{
        sx: {
          overflowX: 'hidden',
          display: 'flex',
          alignItems: 'center'
        }
      }}>
      <Box className="dialog-close-btn">
        <IconButton onClick={handleClose} aria-label="close">
          <CloseRounded />
        </IconButton>
      </Box>

      <DialogContent className="dialog-content">
        <video
          className="video-tag"
          src={`${process.env.PUBLIC_URL}/assets/VideoTutorial/Untitled-design-2.mp4`}
          controls
          autoPlay>
          Your browser does not support the video tag.
        </video>
      </DialogContent>
    </Dialog>
  </Box>
);

export default VideoModal;
