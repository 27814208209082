/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import { ChevronRightOutlined, CloseRounded } from '@mui/icons-material';
import {
  ButtonBase,
  Box,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  styled,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  menuList,
  mostRecentlyVisitedIndianPlace,
  mostRecentlyVisitedWorldPlace
} from '../../constants/header';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import {
  capitalizeFirstLetter,
  checkZoneTitle,
  convertToHyphenatedLowerCase,
  getUrlAfterDomain,
  transformDomasticToMobileDivision,
  transformInternationalToMobileDivision
} from '../../Helper/global';
import KesariLogo from '../../assets/Home/Footer/Footer_logo-new.webp';
import vector_Call from '../../assets/Header/Vector.svg';
import Vector_Heart from '../../assets/Header/Vector_Heart.svg';
import Vector_signin from '../../assets/Header/Vector_signin.svg';
import Shopicon from '../../assets/Header/Shopicon.svg';
import Shopicon_up from '../../assets/Header/Shopicon_up.svg';
import Vector_Toggle from '../../assets/Header/Vector_Toggle.svg';
import SearchDestination from '../Header/SearchDestination';
import LoginModal from '../../components/conversational/modal/LoginModal/LoginModal';
import SignupModal from '../../components/conversational/modal/SignupModal/SignUpModal';
import ForgotPasswordModal from '../../components/conversational/modal/ForgotPasswordModal/ForgotPasswordModal';
import ContactUsModal from './ContactUsViaCall';
import CurrencyDropdown from '../../components/CurrencyDropdown/CurrencyDropdown';
import Years40Logo from '../../assets/Images/40-years-logo.png';

const Header = ({ discoverGemsRef, themedEscapesRef, readyToBookRef }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [openSelectedMenu, setOpenSelectedMenu] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElForNestedMenu, setAnchorElForNestedMenu] = useState(null);
  const [isBackdropActive, setIsBackdropActive] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(0);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const [isOpenSignIn, setIsOpenSignIn] = useState(false);
  const [isOpenSignup, setIsOpenSignup] = useState(false);
  const [isForgotPassOpen, setIsForgotPassOpen] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const firstMenuItemRef = useRef(null);
  const menuListRef = useRef(null);
  const menuContainerRef = useRef(null);
  const openForNestedMenu = Boolean(anchorElForNestedMenu);
  const open = Boolean(anchorEl);
  const [isCall, setIsCall] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuContainerRef.current && !menuContainerRef.current.contains(event.target)) {
        handleCloseForNestedMenu();
        setOpenSelectedMenu(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpen = () => setIsCall(true);
  const handleClose = () => setIsCall(false);

  const openSignup = () => setIsOpenSignup(true);
  const closeSignup = () => setIsOpenSignup(false);
  const openLogin = () => setIsOpenSignIn(true);
  const closeLogin = () => setIsOpenSignIn(false);
  const openForgotPass = () => {
    setIsForgotPassOpen(true);
    closeLogin();
  };
  const closeForgotPass = () => setIsForgotPassOpen(false);

  const switchForgotPassToLogin = () => {
    closeForgotPass();
    openLogin();
  };

  const switchToSignup = () => {
    closeLogin();
    openSignup();
  };

  const switchToLogin = () => {
    closeSignup();
    openLogin();
  };

  const getHeaderMenuListApi = async () => {
    try {
      const headerMenuRes = await api.get(`${BASE_URL}${API_URL.HEADER_MENU}`);
      if (headerMenuRes.status) {
        setHeaderData(headerMenuRes.data);
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    if (menuListRef.current) {
      setPopoverWidth(menuListRef.current.offsetWidth);
    }
  }, [menuListRef]);

  const handleMenuClick = (event, index) => {
    const selectedMenu = menuList[index].menu;

    if (selectedMenu === 'Ready To Book') {
      if (pathname?.split('/')[1] !== '') {
        navigate('/');
      } else {
        readyToBookRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (selectedMenu === 'Themed Experience') {
      if (pathname?.split('/')[1] !== '') {
        navigate('/');
      } else {
        themedEscapesRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (menuList[index].drop) {
      if (openSelectedMenu === index) {
        handleCloseForNestedMenu();
        setOpenSelectedMenu(null);
      } else {
        handleOpenMenu(index);
        handleClickForNestedMenu(event);
        setAnchorElForNestedMenu(firstMenuItemRef.current);
      }
    } else {
      handleCloseForNestedMenu();
    }
  };

  const handleMobileMenu = (event, index) => {
    const selectedMenu = menuList[index].menu;
    if (selectedMenu === 'Ready To Book') {
      if (pathname?.split('/')[1] !== '') {
        navigate('/');
      } else {
        readyToBookRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setAnchorEl(null);
      }
    } else if (selectedMenu === 'Themed Experience') {
      if (pathname?.split('/')[1] !== '') {
        navigate('/');
      } else {
        themedEscapesRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setAnchorEl(null);
      }
    }

    if (menuList[index].drop) {
      handleOpenMenu(index);
    }
  };

  useEffect(() => {
    getHeaderMenuListApi();
    const selectedIndex = sessionStorage.getItem('menuIndex');
    const scrollTarget = menuList[selectedIndex]?.menu;
    if (scrollTarget) {
      setTimeout(() => {
        if (scrollTarget === 'Ready To Book') {
          readyToBookRef?.current?.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollTarget === 'Themed Experience') {
          themedEscapesRef?.current?.scrollIntoView({ behavior: 'smooth' });
          sessionStorage.setItem('menuIndex', null);
        }
      }, 100);
      setTimeout(() => {
        sessionStorage.setItem('menuIndex', null);
      }, 1000);
    }
  }, [pathname, readyToBookRef, themedEscapesRef]);

  const handleNavigation = (url) => {
    const relativeUrl = getUrlAfterDomain(url);
    navigate(relativeUrl);
  };

  const handleClickForNestedMenu = (event) => {
    setAnchorElForNestedMenu(event.currentTarget);
    setIsBackdropActive(true);
  };

  const handleCloseForNestedMenu = () => {
    setAnchorElForNestedMenu(null);
    setIsBackdropActive(false);
    setOpenSelectedMenu('');
    setSelectedDivision(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMenu = (i) => {
    if (i === openSelectedMenu) {
      setOpenSelectedMenu();
    } else {
      setOpenSelectedMenu(i);
    }
  };

  const handleCloseMobileMenu = () => {
    setAnchorEl(null);
    setOpenSelectedMenu(null);
    setSelectedDivision(0);
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateClick = (index) => {
    setSelectedState(selectedState === index ? null : index);
    setSelectedCity(null);
  };

  const handleCityClick = (zoneTitle, countryTitle, stateTitle, cityTitle, index) => {
    setSelectedCity(selectedCity === index ? null : index);
    setOpenSelectedMenu(null);
    navigate(
      `/explore/${checkZoneTitle(zoneTitle)}/${convertToHyphenatedLowerCase(
        zoneTitle
      )}/${convertToHyphenatedLowerCase(countryTitle)}/${convertToHyphenatedLowerCase(stateTitle)}`,
      {
        state: {
          ...(openSelectedMenu === 0 ? { zone: zoneTitle } : { continent: zoneTitle }),
          ...(openSelectedMenu === 1 && { country: countryTitle }),
          state: stateTitle,
          city: cityTitle
        }
      }
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  const id = open ? 'simple-popover' : undefined;

  const domesticData = transformDomasticToMobileDivision(headerData && headerData.Domestic);
  const internationalData = transformInternationalToMobileDivision(
    headerData && headerData?.International
  );

  // Desktop menu helper functions Start
  const renderRecentlyVisitedPlaces = () => {
    const places =
      openSelectedMenu === 0 ? mostRecentlyVisitedIndianPlace : mostRecentlyVisitedWorldPlace;
    return places.length > 0
      ? places.map((item, index) => (
          <span className="mostly-visited-place-item" key={index}>
            <span
              style={{
                cursor: 'pointer',
                color: '#000000B2',
                marginRight: '10px'
              }}
              onClick={() => {
                handleNavigation(item.url);
                handleCloseForNestedMenu();
              }}>
              {item.title}
            </span>
            {places.length !== index + 1 && <span style={{ color: '#000000B2' }}>|</span>}
          </span>
        ))
      : null;
  };

  const renderMenuZones = () => {
    const data = openSelectedMenu === 0 ? domesticData : internationalData;
    return data.length > 0
      ? data.map((item, index) => (
          <Box
            className="sidebar"
            key={index}
            onClick={() => setSelectedDivision(index)}
            sx={{
              background: selectedDivision === index ? 'rgba(39, 48, 107, 0.05)' : '',
              color: selectedDivision === index ? '#27306B' : '',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Typography
              className={
                selectedDivision === index ? 'sidebar-menu-title-active' : 'sidebar-menu-title'
              }>
              {openSelectedMenu === 0 ? item.zone : item.continent}
            </Typography>
            <ChevronRightOutlined sx={{ width: 30, fontSize: 30 }} />
          </Box>
        ))
      : null;
  };

  const renderMenuItems = () => {
    const data = openSelectedMenu === 0 ? domesticData : internationalData;
    const countriesOrStates = data[selectedDivision]?.countries || data[selectedDivision]?.states;

    return [0, 1, 2].map((partIndex) => {
      const partSize = Math.ceil(countriesOrStates?.length / 3);
      const partItems = countriesOrStates?.slice(partIndex * partSize, (partIndex + 1) * partSize);

      return (
        <Box key={partIndex} sx={{ width: '30%', backgroundColor: '#f4f4f7' }}>
          {partItems?.map((item, itemIndex) => (
            <Box className="render-item-title" key={itemIndex} sx={{ textAlign: 'left' }}>
              {renderItemTitle(item)}
              {item.isCity && renderCities(item.cities)}
            </Box>
          ))}
        </Box>
      );
    });
  };

  const renderItemTitle = (item) => {
    const data = openSelectedMenu === 0 ? domesticData : internationalData;
    const isDomestic = openSelectedMenu === 0;

    return (
      <Box
        sx={{
          marginBottom: '10px',
          fontFamily: 'gothamBold',
          cursor: item.isCity ? 'default' : 'pointer',
          '&:hover': { color: item.isCity ? 'inherit' : '#007bff' }
        }}
        onClick={() => {
          if (!item.isCity) {
            navigate(
              isDomestic
                ? `/explore/india/${convertToHyphenatedLowerCase(
                    data[selectedDivision].zone
                  )}/${convertToHyphenatedLowerCase(item.title)}`
                : `/explore/world/${convertToHyphenatedLowerCase(
                    item?.countryContinent
                  )}/${convertToHyphenatedLowerCase(item.country)}`
            );
            handleCloseForNestedMenu();
          }
        }}>
        {isDomestic
          ? item.title
          : item.country === 'United States'
          ? 'United States of America'
          : item.country}
      </Box>
    );
  };

  const renderCities = (cities) => {
    const data = openSelectedMenu === 0 ? domesticData : internationalData;
    return (
      <Box>
        {cities.map((city, cityIndex) => (
          <Box
            key={cityIndex}
            sx={{
              marginBottom: cityIndex === cities.length - 1 ? '12px' : '2px',
              padding: '2px 0',
              cursor: 'pointer',
              '&:hover': { color: '#27306B' }
            }}
            onClick={() => {
              navigate(
                `/explore/india/${convertToHyphenatedLowerCase(
                  data[selectedDivision].zone
                )}/${convertToHyphenatedLowerCase(
                  data[selectedDivision].title
                )}/${convertToHyphenatedLowerCase(city.title)}`
              );
              handleCloseForNestedMenu();
            }}>
            {capitalizeFirstLetter(city.title)}
          </Box>
        ))}
      </Box>
    );
  };
  // Desktop menu helper functions End

  return (
    <Box className="header-container-kesari">
      {/* <Box className="header-container-kesari" sx={{ zIndex: isMobile ? 1000 : 2000 }}></Box> */}
      {/* Mobile Menu */}
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#27306b !important',
          height: {
            xs: '70px',
            sm: '80px',
            md: '80px',
            lg: '80px',
            xl: '80px'
          }
        }}>
        <Grid container sx={{ height: { xs: '44px', sm: '44px' } }}>
          <Grid item xs={12} sx={{ display: 'display', justifyContent: 'center' }}>
            <Grid className="Vector_Toggle" sx={{ top: '22px' }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'drawer-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}>
                <img src={Vector_Toggle} alt="" />
              </IconButton>
              <Drawer
                anchor="left"
                open={open}
                onClose={handleCloseMobileMenu}
                PaperProps={{
                  sx: { width: '25ch', backgroundColor: '#27306B', color: '#FFFFFF' }
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px'
                  }}>
                  <IconButton
                    onClick={handleCloseMobileMenu}
                    sx={{
                      color: '#FFFFFF',
                      marginLeft: '5px'
                    }}
                    aria-label="close">
                    <CloseRounded />
                  </IconButton>
                </Box>

                <List>
                  {menuList.map((option, index) => (
                    <Box key={index}>
                      <ListItem>
                        <ButtonBase
                          onClick={(e) => handleMobileMenu(e, index)}
                          sx={{
                            width: '100%',
                            color: '#FFFFFF',
                            '&:hover': {
                              backgroundColor: '#3E4A89'
                            }
                          }}>
                          <ListItemText
                            className="mobile-menu-list"
                            primary={option.menu}
                            sx={{
                              '& .MuiListItemText-primary': {
                                fontFamily: 'gothamBook'
                              }
                            }}
                          />
                          {option.drop && (
                            <img
                              src={openSelectedMenu === index ? Shopicon_up : Shopicon}
                              alt={openSelectedMenu === index ? 'up' : 'down'}
                              style={{
                                marginRight: '10px',
                                filter: openSelectedMenu === index ? 'invert(100%)' : 'none'
                              }}
                            />
                          )}
                        </ButtonBase>
                      </ListItem>
                      {option.drop && openSelectedMenu === index && (
                        <Collapse in timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {(openSelectedMenu === 0 ? domesticData : internationalData)?.map(
                              (division, divIndex) => (
                                <Box key={divIndex}>
                                  <ListItem
                                    sx={{
                                      backgroundColor:
                                        selectedDivision === divIndex ? '#b3e5fc' : '#e0f7fa',
                                      color: selectedDivision === divIndex ? '#27306B' : '#00000099'
                                    }}>
                                    <ButtonBase
                                      onClick={() => setSelectedDivision(divIndex)}
                                      sx={{ width: '100%' }}>
                                      <ListItemText
                                        primary={
                                          openSelectedMenu === 0
                                            ? division.zone
                                            : division.continent
                                        }
                                        sx={{
                                          '& .MuiListItemText-primary': {
                                            fontFamily:
                                              selectedDivision === divIndex
                                                ? 'gothamBold'
                                                : 'gothamMedium'
                                          }
                                        }}
                                      />
                                      {selectedDivision === divIndex ? (
                                        <img
                                          src={Shopicon_up}
                                          alt="up"
                                          style={{ marginRight: '10px' }}
                                        />
                                      ) : (
                                        <img
                                          src={Shopicon}
                                          alt="down"
                                          style={{
                                            filter:
                                              'invert(45%) sepia(45%) saturate(500%) hue-rotate(200deg) brightness(70%) contrast(120%)',
                                            marginRight: '10px'
                                          }}
                                        />
                                      )}
                                    </ButtonBase>
                                  </ListItem>
                                  <Collapse
                                    in={selectedDivision === divIndex}
                                    timeout="auto"
                                    unmountOnExit>
                                    <List component="div" disablePadding>
                                      {openSelectedMenu === 0
                                        ? division.states.map((state, stateIndex) => (
                                            <Box key={stateIndex}>
                                              <ListItem
                                                sx={{
                                                  backgroundColor:
                                                    selectedState === stateIndex
                                                      ? '#c8e6c9'
                                                      : '#f1f8e9',
                                                  paddingLeft: '20px',
                                                  color: '#00000099'
                                                }}>
                                                <ButtonBase
                                                  onClick={() => {
                                                    if (state.isCity) {
                                                      handleStateClick(stateIndex);
                                                    } else {
                                                      navigate(
                                                        `/explore/india/${convertToHyphenatedLowerCase(
                                                          division.zone
                                                        )}/${convertToHyphenatedLowerCase(
                                                          state.title
                                                        )}`,
                                                        {
                                                          state: {
                                                            zone: division.zone,
                                                            state: state.title
                                                          }
                                                        }
                                                      );
                                                      handleCloseForNestedMenu();
                                                      handleCloseMobileMenu();
                                                    }
                                                  }}
                                                  sx={{ width: '100%' }}>
                                                  <ListItemText
                                                    primary={state.title}
                                                    sx={{
                                                      '& .MuiListItemText-primary': {
                                                        fontFamily:
                                                          selectedState === stateIndex
                                                            ? 'gothamBold'
                                                            : 'gothamMedium'
                                                      }
                                                    }}
                                                  />
                                                  {state.isCity &&
                                                    (selectedState === stateIndex ? (
                                                      <img
                                                        src={Shopicon_up}
                                                        alt="up"
                                                        style={{
                                                          marginRight: '10px'
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={Shopicon}
                                                        alt="down"
                                                        style={{
                                                          marginRight: '10px',
                                                          filter:
                                                            'invert(45%) sepia(45%) saturate(500%) hue-rotate(200deg) brightness(70%) contrast(120%)'
                                                        }}
                                                      />
                                                    ))}
                                                </ButtonBase>
                                              </ListItem>
                                              {state.isCity && (
                                                <Collapse
                                                  in={selectedState === stateIndex}
                                                  timeout="auto"
                                                  unmountOnExit>
                                                  <List component="div" disablePadding>
                                                    {state.cities.map((city, cityIndex) => (
                                                      <ListItem
                                                        key={cityIndex}
                                                        sx={{
                                                          backgroundColor:
                                                            selectedCity === cityIndex
                                                              ? '#ffccbc'
                                                              : '#ffe0b2',
                                                          paddingLeft: '40px',
                                                          color: '#00000099',
                                                          '&:hover': { cursor: 'pointer' }
                                                        }}>
                                                        <ButtonBase
                                                          onClick={() =>
                                                            handleCityClick(
                                                              division.zone,
                                                              state.title,
                                                              city.title,
                                                              cityIndex
                                                            )
                                                          }
                                                          sx={{ width: '100%' }}>
                                                          <ListItemText
                                                            primary={capitalizeFirstLetter(
                                                              city.title
                                                            )}
                                                            sx={{
                                                              fontFamily:
                                                                selectedCity === cityIndex
                                                                  ? 'gothamBold'
                                                                  : 'gothamMedium'
                                                            }}
                                                          />
                                                        </ButtonBase>
                                                      </ListItem>
                                                    ))}
                                                  </List>
                                                </Collapse>
                                              )}
                                            </Box>
                                          ))
                                        : division.countries.map((country, countryIndex) => (
                                            <Box key={countryIndex}>
                                              <ListItem
                                                style={{
                                                  backgroundColor:
                                                    selectedCountry === countryIndex
                                                      ? '#c8e6c9'
                                                      : '#f1f8e9',
                                                  paddingLeft: '20px',
                                                  color: '#00000099'
                                                }}>
                                                <ButtonBase
                                                  onClick={() => {
                                                    navigate(
                                                      `/explore/world/${convertToHyphenatedLowerCase(
                                                        country.countryContinent
                                                      )}/${convertToHyphenatedLowerCase(
                                                        country.country
                                                      )}`
                                                    );
                                                    handleCloseForNestedMenu();
                                                    handleCloseMobileMenu();
                                                  }}
                                                  sx={{ width: '100%' }}>
                                                  <ListItemText
                                                    primary={
                                                      country.country === 'United States'
                                                        ? 'United State of America'
                                                        : country.country
                                                    }
                                                    sx={{
                                                      fontFamily:
                                                        selectedCountry === countryIndex
                                                          ? 'gothamBold'
                                                          : 'gothamMedium'
                                                    }}
                                                  />
                                                </ButtonBase>
                                              </ListItem>
                                            </Box>
                                          ))}
                                    </List>
                                  </Collapse>
                                </Box>
                              )
                            )}
                          </List>
                        </Collapse>
                      )}
                    </Box>
                  ))}
                </List>
                <Divider sx={{ my: 5, borderColor: 'grey.400', borderWidth: '1px' }} />
                <img
                  src={KesariLogo}
                  alt="mobil-menu-brand-logo"
                  onClick={() => {
                    navigate('/');
                    setAnchorEl(null);
                  }}
                  style={{ cursor: 'pointer', width: '90%', height: '15%', marginLeft: '12px' }}
                />
              </Drawer>
            </Grid>

            <Grid className="kesari" sx={{ cursor: 'pointer', zIndex: { lg: 6000 } }}>
              <img
                src={KesariLogo}
                alt="desktop-brand-logo"
                onClick={() => {
                  handleCloseForNestedMenu();
                  navigate('/');
                }}
              />
            </Grid>
            <SearchDestination />
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={6}
              xl={8}
              sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100%' }}>
              <Typography
                variant="h6"
                sx={{
                  display: { xs: 'none', sm: 'block', md: 'block' },
                  color: '#FFFFFF',
                  textAlign: 'center',
                  mt: 1,
                  ml: { sm: '645px', md: '702px', lg: '800px', xl: '810px' },
                  whiteSpace: 'nowrap',
                  fontFamily: 'gothamBold',
                  fontSize: { sm: '15px', md: '10px', lg: '16px', xl: '18px' }
                }}>
                CUSTOMIZED HOLIDAYS
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>
              <Box
                className="call-box"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                    md: 'flex'
                  },
                  gap: '35px',
                  color: 'white'
                }}>
                <Grid
                  onClick={() => {
                    handleOpen();
                    handleCloseForNestedMenu();
                  }}
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    color: 'white',
                    cursor: 'pointer',
                    alignItems: 'center',
                    zIndex: 6000
                  }}>
                  <img
                    src={vector_Call}
                    alt="vector_Call"
                    style={{ width: '16px', height: '14px' }}
                  />
                  <span className="call desktop-header-items">Contact Us</span>
                </Grid>
                {isCall && <ContactUsModal open={handleOpen} handleClose={handleClose} />}
                {/* <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    color: 'white',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                  <img src={Vector_Heart} alt="Vector_Heart" />
                  <span className="call desktop-header-items">Wishlist</span>
                </Grid> */}
                {/* <Grid
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    color: 'white',
                    cursor: 'pointer',
                    alignItems: 'center'
                  }}
                  onClick={openLogin}>
                  <img src={Vector_signin} alt="Vector_signin" />
                  <span className="desktop-header-items">Sign In</span>
                </Grid> */}
                <Grid
                  sx={{
                    display: 'flex',
                    color: 'white',
                    alignItems: 'center',
                    cursor: 'pointer',
                    position: 'relative',
                    zIndex: 6000
                  }}
                  onClick={() => {
                    handleCloseForNestedMenu();
                  }}>
                  <CurrencyDropdown />
                </Grid>
              </Box>
              <Box
                className="years-logo"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex'
                  }
                }}>
                <img src={Years40Logo} alt="40 Years Logo" width="90px" height="90px" />
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: 'white',
                alignItems: 'center'
              }}>
              <Box className="mobile-view">
                <Box className="login-button">
                  <CurrencyDropdown />
                  {/* <Button
                    variant="outlined"
                    className="log_btn desktop-header-items"
                    onClick={openLogin}>
                    Login
                  </Button> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Desktop Menu */}
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          display: { xs: 'none', sm: 'flex', md: 'flex' },
          alignItems: 'center',
          backgroundColor: '#20285B !important',
          height: '45px'
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ul
              ref={menuListRef}
              className="dropdown_menu"
              style={{
                listStyleType: 'none',
                display: 'flex',
                color: 'white',
                padding: 0,
                margin: 0
              }}>
              {menuList.map((item, i) => (
                <li
                  key={i}
                  ref={i === 0 ? firstMenuItemRef : null}
                  onClick={(event) => handleMenuClick(event, i)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: openSelectedMenu === i ? '30px' : '22px'
                  }}
                  className={
                    openSelectedMenu === i
                      ? 'openMenu desktop-menu-list'
                      : 'closeMenu desktop-menu-list'
                  }
                  aria-describedby={id}>
                  {item.menu}
                  {item.drop && (
                    <img src={openSelectedMenu === i ? Shopicon_up : Shopicon} alt="" />
                  )}
                </li>
              ))}
            </ul>

            <Popover
              ref={menuContainerRef}
              id={id}
              open={openForNestedMenu}
              anchorEl={anchorElForNestedMenu}
              onClose={handleCloseForNestedMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              sx={{
                '& .MuiPopover-paper': {
                  width: `${popoverWidth}px`,
                  height: '400px',
                  fontFamily: 'inherit'
                }
              }}>
              <Box className="mostly-visited-place">
                <span className="mostly-visited-place-title">Mostly Visited Places: </span>
                <span>{renderRecentlyVisitedPlaces()}</span>
              </Box>

              <Grid container className="menu-container">
                <Grid item sx={{ width: '280px !important' }}>
                  <Item sx={{ padding: '0px !important' }}>{renderMenuZones()}</Item>
                </Grid>

                <Grid item sx={{ backgroundColor: '#f4f4f7', flex: '1' }}>
                  <Item sx={{ backgroundColor: '#f4f4f7' }}>
                    <Box sx={{ width: '100%', backgroundColor: '#f4f4f7' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          p: 1,
                          m: 1,
                          bgColor: '#f4f4f7 !important',
                          borderRadius: 1
                        }}>
                        {renderMenuItems()}
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
      </Box>

      <LoginModal
        open={isOpenSignIn}
        handleCloseLoginModal={closeLogin}
        handleSignup={switchToSignup}
        openForgotPassModal={openForgotPass}
      />
      <SignupModal open={isOpenSignup} handleClose={closeSignup} handleLogin={switchToLogin} />
      <ForgotPasswordModal
        open={isForgotPassOpen}
        handleClose={closeForgotPass}
        handleLogin={switchForgotPassToLogin}
      />
    </Box>
  );
};

export default Header;
