import React from 'react';
import { Modal, Box } from '@mui/material';
import { Close } from '@mui/icons-material';

const CustomModal = ({ open, onClose, children, hideBackdrop, closable, sx }) => (
  <Modal
    open={open}
    onClose={onClose}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    hideBackdrop={hideBackdrop}>
    <Box sx={{ position: 'relative', ...sx }}>
      {closable && (
        <Close
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 31,
            right: 34,
            zIndex: 20,
            width: 20,
            height: 20,
            cursor: 'pointer'
          }}
        />
      )}
      {children}
    </Box>
  </Modal>
);

export default CustomModal;
